.footer {
  border-top: none;
  background: #bfbfbf;
  position: relative;
  width: 100%;
  min-height: 200px;
  text-align: center;
  padding: 0 10px;
}
.pcFooter {
  max-width: 1200px;
  padding: 20px 20px;
  margin: 0 auto;
}
.pcFooterMenu {
  max-width: 1000px;
  margin: 0 auto;
  flex-grow: 1;
}
.pcFooterMenuBox {
  flex-grow: 1;
  text-align: left;
}
.footerItemHead {
  font-size: 16px;
  color: #fff;
}
.footerItem {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  margin: 6px 0;
}

.mobFooterBtnRow {
  max-width: 400px;
  margin: 0 auto;
  flex-grow: 1;
  display: none;
}

.mobFooterBtn {
  padding: 6px 8px;
  width: auto;
  flex-grow: 1;
  transition: background 1s;
}
.mobFooterBtnAvatar {
  padding: 2px 8px 0 8px;
  width: auto;
  flex-grow: 1;
  transition: background 1s;
}
.mobFooterBtn:hover {
  background: #e8e8e8;
}

.header {
  width: 100%;
  padding: 0 10px;
  /*display: none;*/
}

.pcHeader {
  max-width: 1116px;
  padding: 12px 20px 0 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-grow: 1;
  position: relative;
  background: #fff;
}

.headDropCat {
  background: #0c6191;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
  padding: 6px 10px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.headDrop {
  background: #0c6191;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
  padding: 6px 20px;
  margin: 0 12px;
  width: 90%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.headDrop a {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.searchBox {
  border-bottom: none;
  max-width: 1116px;
  margin: 0 auto;
  padding: 0 16px 16px 16px;
  background: #fff;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 0 0 #fff;
}

.headNaviMenu {
  border: 1px solid #67a2b8;
  box-sizing: border-box;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 2px 4px 0 4px;
  font-size: 13px;
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 600px) {
  .footer {
    border-top: 1px solid #e8e8e8;
    position: fixed;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 40px;
    min-height: 40px;
    background-color: white;
    text-align: center;
    padding: 0 10px;
    z-index: 10000;
  }
  .mobFooterBtnRow {
    display: flex;
  }
  .pcFooter {
    display: none;
  }

  .header {
    text-align: center;
    padding: 0 10px;
    display: flex;
  }

  .searchBox {
    box-shadow: 0 1px 4px 0 #ddd;
    max-width: 1116px;
    margin: 0 auto;
    padding: 0 16px 8px 16px;
    background: #fff;
    border-radius: 0 0 10px 10px;
  }
}

.cabinetTabs {
  border: none;
  transition: background-color 1s;
  box-sizing: border-box;
  /*flex: 1;*/
  min-width: 50px;
  justify-content: flex-start;
  margin: 0 0;
  padding: 4px 6px 2px 4px;
  height: auto;
}
.cabinetTabs div {
  text-align: center;
  min-width: 40px;
  font-size: 12px;
  padding: 4px 0px 2px 0px;
}

.cabinetTabs:hover {
  background: #d0daec;
}

.onlyPc {
  display: block;
}
.onlyMob {
  display: none;
}

.notCollapsed {
}

.cabinetHead {
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px 0 0 0;
  background: #fff;
}

.cabinetAvatar {
  text-align: center;
  margin: 0 20px 10px 0;
}

.cabinetAvatar img {
  border-radius: 50px;
  position: relative;
}
.cabinetAvatar img:hover {
  box-shadow: 0 0 3px 1px #3991c4;
  cursor: pointer;
}

.cabinetAvatar img {
  max-width: 100px;
  transition: max-width 0.5s;
  border-radius: 50px;
}

.cabinetChatAvatar {
  text-align: center;
  margin: 0 20px 10px 0;
}

.cabinetChatAvatar img {
  border-radius: 50px;
  position: relative;
}
.cabinetChatAvatar img:hover {
  box-shadow: 0 0 3px 1px #3991c4;
  cursor: pointer;
}

.cabinetChatAvatar img {
  max-width: 40px;
  transition: max-width 0.5s;
  border-radius: 50px;
}
.reloadAvatar {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 30;
}

.cabinetFio {
}
.cabinetFio .fio {
  margin: 6px 0 0 0;
  font-size: 24px;
  font-weight: bold;
}
.cabinetFio .area {
  margin: 12px 0 0 0;
  font-size: 16px;
  font-weight: bold;
  transition:
    top 1s,
    font-size 1s,
    left 1s;
}

.cabinetNav {
  padding: 20px 0;
  margin: 0 auto;
  max-width: 500px;
  background: #fff;
}

@media screen and (max-width: 600px) {
  .pcHeader {
    padding: 8px 8px 0 8px;
  }

  .notCollapsed {
    padding: 0 0 0 16px;
  }

  .onlyPc {
    display: none;
  }
  .onlyMob {
    display: block;
  }

  .cabinetHead {
    display: inline;
  }
  .cabinetFio {
    text-align: center;
  }
}
