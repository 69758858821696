.cardBox {
  width: 100%;
  margin: 12px 0 4px 0;
  min-height: 20px;
  padding: 0;
}

.cardBody {
  padding: 40px 0 40px 0;
}

.cardH4 {
  margin: 0 0 20px 0;
}

.cardTag {
  margin: 6px 0 0 0;
}

.cardBox {
  width: 100%;
  margin: 4px 0 4px 0;
  padding: 0;
}

.cardColBox {
  width: 100%;
}

.cardBodyChat {
  min-height: 4px;
}

.cardChatSpaceDirection {
  width: 100%;
  justify-content: center;
}

.cardChatSpaceDirectionMessage {
  width: 100%;
  justify-content: center;
}

.cardDiv {
  padding: 20px 20px;
}

.cardLinkKey {
  color: #0c6191;
}

.cardsRow {
  display: flex;
}

.cardsDiv {
  width: 100%;
  text-align: center;
  color: #444444;
}

.cardsLink {
  margin: 0 12px 0 0;
}

.cardsSpan {
  font-size: 13px;
  color: #777;
  margin: 0 6px;
}

.cardsListingLink {
  margin: 0 0 0 12px;
}

.imEditorDiv {
  height: 45px;
  padding: 4px 4px 0 4px;
}

.imEditorButton {
  margin: 0 6px 0 6px;
}

.imEditorButtonFloat {
  float: right;
}

.signUpRow {
  text-align: center;
  margin: 40px 0 40px 0;
}

.signUpRowDiv {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #0c6191;
  margin-bottom: 0;
}

.signUpMarginBottom {
  margin-bottom: 10px;
}

.signUpColSpan {
  padding: 0 0 0 0;
}

.signUpColSpanL {
  text-align: right;
  padding: 8px 0 8px 0;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.333333px;
  color: #555;
}

.signUpFormDiv {
  color: #ff4d4f;
}

.signUpLink {
  margin-left: 6px;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.333333px;
  color: #0c6091;
}

.signUpRowCol {
  padding: 0 0 8px 0;
  color: #ff4d4f;
}

.signUpRowColDiv {
  border-top: 1px solid #f0f0f0;
  align-self: stretch;
  margin: 4px 0px 6px 0;
  height: 10px;
  text-align: center;
  position: relative;
}

.signUpRowColDivIn {
  position: absolute;
  top: -12px;
  left: 45%;
  z-index: 100;
  width: 10%;
  background: #fff;
}

.accountsRow {
  min-height: 200px;
  padding-top: 20px;
}

.accountsRow1 {
  min-height: 200px;
  padding-top: 40px;
}

.accountsColSpan {
  text-align: center;
  margin: 0px 0 40px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: -0.333333px;
  margin-bottom: 20px;
  color: #3b88c3;
}

.accountsListDiv {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

.accountsDiv {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  margin: 0 15px;
}

.accoutsRowCol {
  padding: 0 0 10px 0;
  color: #ff4d4f;
}

.chatsDiv {
  display: flex;
  min-height: 60px;
  padding: 4px 0 4px 12px;
  border-bottom: 1px solid #ddd;
}

.chatsAvatar {
  max-width: 36px;
  border-radius: 100px;
  margin: 4px 16px 0 0;
}

.chatsDiv1 {
  flex: 1;
}

.chatsDiv2 {
  position: relative;
  width: 100%;
}

.chatsDiv3 {
  position: absolute;
  top: 4px;
  right: 10px;
  color: #999;
}

.chatsDiv4 {
  padding: 0 0 4px 2px;
  position: relative;
  color: #555;
  min-height: 14px;
}

.chatsDiv5 {
  font-size: 13px;
}

.chatsDiv6 {
  cursor: pointer;
  position: absolute;
  z-index: 500;
  top: 4px;
  right: 10px;
  color: #999;
}

.chatsDiv7 {
  max-width: 600px;
  padding: 10px 14px;
  margin: 40px auto;
}

.chatsDiv8 {
  padding: 10px 0 10px 0;
  font-weight: bold;
  font-size: 16px;
}

.chatsDiv9 {
  padding: 10px 0 10px 0;
  font-weight: bold;
  font-size: 16px;
}

.chatsSpan {
  color: #aaa;
  font-size: 11px;
  margin-left: 8px;
}

.headerDiv {
  padding: 4px 8px;
}

.headerDiv1 {
  display: flex;
}

.headerDiv2 {
  width: 24px;
  height: 24px;
  text-align: center;
}

.headerDiv3 {
  border: 1px solid #888;
  border-radius: 40px;
  overflow: hidden;
  height: 24px;
  width: 24px;
  text-align: center;
}

.headerDiv4 {
  padding: 3px 0 0 6px;
}

.headerDiv5 {
  margin-left: auto;
}

.headerDiv6 {
  padding: 4px 0 0 20px;
  position: absolute;
  top: 2px;
  right: 10px;
}

.headerZotzonAvatar {
  width: 20px;
  border-radius: 20px;
}

.headerUserOutlined {
  margin: 0 0;
}

.headerMoreOutlined {
  cursor: pointer;
}

.cardTitleParagraph {
  margin: 0 0 4px 0;
}

.cardTitlelink {
  color: #0c6191;
}

.cardTitleLinkKey {
  color: #0c6191;
}

.cardTitleDiv {
  margin: 6px 0 0 0;
}

.cardTitleTag {
  margin: 6px 0 0 0;
}

.searchDiv {
  margin-top: 40px;
}

.searchDiv1 {
  margin-top: -20px;
}

.searchDiv2 {
  position: absolute;
  top: 140px;
  z-index: 1000;
  left: 48vw;
}

.searchDiv3 {
  margin: 10px auto;
  width: 40px;
}

.searchDiv4 {
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 120px;
  max-width: 1116px;
}

.searchDiv5 {
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 1116px;
}

.searchDiv6 {
  width: 100%;
  text-align: center;
  color: #444444;
  margin: 0 0 20px 0;
}

.searchCol {
  margin-right: 20px;
  border-right: 1px solid #999;
  padding-right: 20px;
}

.messageOutlined {
  font-size: 22px;
}

.searchA {
  margin: 0 12px 0 0;
}

.searchA1 {
  margin: 0 0 0 12px;
}

.searchSpan {
  font-size: 13px;
  color: #777;
  margin: 0 6px;
}
