.onlyInMobile {
  display: none;
}
.aRow {
  max-width: 1600px;
  margin: 10px auto;
  background: #d9d9d9;
  padding: 70px 12px 12px 12px;
  border-radius: 20px 20px 10px 10px;
}

.row {
  margin: 0 0 6px 0;
}

.rowR {
}

.card {
  overflow: hidden;
  background: #fff;
  border-radius: 6px;
  width: 18%;
  height: 245px;
  margin: 0.5% 0.5%;
  position: relative;
}

.cardVip {
  overflow: hidden;
  background: #fff;
  border-radius: 6px;
  width: 39%;
  min-height: 500px;
  max-height: 503px;
  margin: 0 0;
  position: relative;
}

.cardVipFooter {
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
}

.cardFooterBtn {
  background: #fff;
  display: none;
  justify-content: space-between;
  border-radius: 6px;
}

.fButton {
  border: 1px solid #c2def6;
  border-radius: 4px;
  margin: 4px 2px 8px 2px;
  padding: 3px 10px;
  cursor: pointer;
  background: #c2def6;
  transition:
    background 0.4s,
    border 0.4s;
  min-width: 30px;
  text-align: center;
  flex: auto;
}

.fButton:hover {
  background: #fff;
  border: 1px solid #c2def6;
}

.privateList {
  padding: 10px 0 10px 0;
  max-width: 1000px;
  margin: 10px auto;
  background: #f7f7f7;
}
.privateList .card {
  height: auto;
}

.subVipRow {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  padding: 0 0 0 12px;
}
.rowR .subVipRow {
  padding: 0 12px 0 0;
}

.subVipRow .card {
  width: 32.5%;
  margin: 0 0 0.5% 0;
}
.subVipRow .card:nth-child(2) {
  margin: 0 1.2% 0.5% 1.2%;
}
.subVipRow .card:nth-child(4) {
  margin-top: 1%;
}
.subVipRow .card:nth-child(5) {
  margin: 1% 1.2% 0.5% 1.2%;
}
.subVipRow .card:nth-child(6) {
  margin-top: 1%;
}

.cardImg {
  overflow: hidden;
  /*border-radius: 6px;*/
  width: 100%;
  margin: 2% auto;
  min-height: 200px;
  min-width: 200px;
  max-height: 320px;
}
.cardImg img {
  width: 100%;
}

.cardTitle {
  text-align: left;
  min-height: 22px;
  padding: 6px 12px 0 12px;
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*display: flex;*/
}
.cardTitle a {
  text-decoration: none;
}

.cardAutorlink {
  color: #222222;
}

.cardTitle a:hover {
  text-decoration: none;
}

.cardVip .cardTitle {
  padding: 6px 12px 0 12px;
  text-align: left;
}

.cardVip .cardTitle a {
  color: #448cab;
}

.cardDesc {
  min-height: 30px;
  padding: 0 12px 0 12px;

  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  width: 100%;
  max-width: 100vw;
}

.cardFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-radius: 0 0 6px 6px;
}

.cardTitleLink {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #67a2b8 !important;
}

.cardTitleLink .ant-typography a {
  color: #67a2b8;
}

.cardTitlelink:hover {
  border-bottom: 1px dashed #67a2b8;
}

.videoIframe {
  width: 100%;
  height: 100%;
  min-height: 140px;
}

.price {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: right;
  color: #141414;
  margin-left: auto;
}

.tag {
}
.tag a {
  font-size: 12px;
  color: #608ea9;
  cursor: pointer;
}
.tag a:hover {
  text-decoration: underline;
  color: #0c6191;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .card {
    border-radius: 6px;
    width: 31%;
    padding: 0;
    margin: 1%;
  }
  .cardImg {
    width: 100%;
    margin: 0 auto;
  }
  .cardImg img {
    width: auto;
    max-width: 100%;
    max-height: 600px;
  }
  .cardVip {
    max-height: none;
    /*width: 32.4%;*/
    /*margin: 0 0.8% 1% 0;*/
  }
}

.gallery {
}
.gallery .image-gallery-image {
  max-height: 240px;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .videoIframe {
    min-height: 800px;
    max-height: 800px;
  }
  .onlyInMobile {
    display: block;
  }
  .aRow {
    padding: 60px 0 0 0;
  }
  .row {
    margin: 0 0 0 0;
  }
  .card {
    border-radius: 6px;
    width: 50%;
    padding: 10px 0;
    height: auto;
    margin: 6px auto;
  }
  .cardImg {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 18px;
    max-height: 800px;
  }
  .cardImg img {
    width: auto;
    max-width: 100%;
    max-height: 800px;
  }

  .cardVip {
    width: 100%;
    height: auto;
    margin: 6px 1% 1% 0;
    max-height: none;
  }

  .subVipRow {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .subVipRow .card {
    width: 100%;
    margin: 6px 0;
  }

  .subVipRow {
    padding: 0 0 0 0;
  }
  .rowR .subVipRow {
    padding: 0 0 0 0;
  }
  .subVipRow .card:nth-child(2) {
    width: 100%;
    padding: 10px 0;
    margin: 6px 0;
  }
  .subVipRow .card:nth-child(5) {
    width: 100%;
    padding: 10px 0;
    margin: 6px 0;
  }

  .cardTitle {
    font-size: 24px;
    padding: 6px 10px 6px 12px;
    width: 100vw;
    max-width: 100vw;
  }
  .cardTitle a {
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    color: #0c6191;
  }
  .cardFooter {
    position: relative;
    bottom: unset;
    left: unset;
  }

  .cardFooterBtn {
    display: flex;
  }
  .fButton {
    width: 18%;
    margin: 4px 4px 12px 4px;
    padding: 3px 5px;
  }

  .cardFooter {
    /*position: relative;*/
  }
}

@media screen and (max-width: 600px) {
  .videoIframe {
    min-height: 250px;
  }

  .onlyInMobile {
    display: block;
  }
  .aRow {
    padding: 60px 0 40px 0;
  }
  .row {
    margin: 0 0 0 0;
  }
  .card {
    border-radius: 6px;
    width: 100%;
    padding: 10px 0;
    height: auto;
    margin: 6px auto;
  }
  .cardImg {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 18px;
    max-height: 66vh;
  }
  .cardImg img {
    width: auto;
    max-width: 100%;
    max-height: 800px;
  }

  .cardVip {
    width: 100%;
    height: auto;
    margin: 6px 0 6px 0;
    max-height: none;
  }

  .subVipRow {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .subVipRow .card {
    width: 100%;
    margin: 6px 0;
  }

  .subVipRow {
    padding: 0 0 0 0;
  }
  .rowR .subVipRow {
    padding: 0 0 0 0;
  }
  .subVipRow .card:nth-child(2) {
    width: 100%;
    padding: 10px 0;
    margin: 6px 0;
  }
  .subVipRow .card:nth-child(5) {
    width: 100%;
    padding: 10px 0;
    margin: 6px 0;
  }

  .cardTitle {
    font-size: 24px;
    padding: 6px 10px 6px 12px;
    width: 100vw;
    max-width: 100vw;
  }
  .cardTitle a {
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    color: #0c6191;
  }
  .cardFooter {
    position: relative;
    bottom: unset;
    left: unset;
  }

  .cardFooterBtn {
    display: none;
  }
  .fButton {
    width: 18%;
    margin: 4px 4px 12px 4px;
    padding: 3px 5px;
  }

  .cardFooter {
    /*position: relative;*/
  }
}

@media screen and (max-width: 400px) {
  .fButton {
    width: auto;
    flex: 1;
    font-size: 11px;
    margin: 4px 2px 12px 2px;
    padding: 3px 5px;
  }

  .cardFooter {
    /*position: relative;*/
  }
}
