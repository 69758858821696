.line {
  text-align: center;
  margin: 60px 0 40px 0;
}

.formRow {
  padding: 0 0 10px 0;
  color: #ff4d4f;
}

.rowCol {
  text-align: right;
  padding: 0 0 10px 0;
}
.link {
  margin-left: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.333333px;
  color: #0c6091;
}

.div {
  border-top: 1px solid #f0f0f0;
  margin: 10px 0px;
}

.div1 {
  text-align: center;
  margin: 30px 0;
  font-family: Roboto;
  font-style: normal;
  font-size: 13px;
  line-height: 3px;
  color: #595959;
}

.link1 {
  font-family: Roboto;
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.333333px;
  color: #0c6091;
}

.rowCol1 {
  text-align: center;
  margin: 60px 0 40px 0;
}

.rowDiv {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #0c6191;
  margin-bottom: 0;
}

.rowCol2 {
  padding: 0 0 10px 0;
  color: #ff4d4f;
}

.formDiv {
  border-top: 1px solid #f0f0f0;
  margin: 10px 0px;
}

.formDiv1 {
  text-align: center;
  margin: 30px 0;
  font-family: Roboto;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  color: #595959;
}

.noAcc {
  font-family: Roboto;
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.333333px;
  color: #0c6091;
}

.divLink {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.333333px;
  color: #0c6091;
}

.div2 {
  background: #fff;
  min-height: 80vh;
  position: relative;
}

.headerDiv {
  padding: 0 0 0 0;
  width: 150px;
}

.emptyLayout {
  background: #d3d3d3;
}

.emptyHeaderDiv {
  padding: 0 0 0 0;
  width: 150px;
}

.publicCabDiv {
  background: #fff;
  min-height: 100vh;
  position: relative;
}

.publicCabHeadDiv {
  padding: 0 0 0 0;
  width: 150px;
}

.footerBtnAvatar {
  padding: 2px 8px 0 8px;
}

.footerDivAvatar {
  border-radius: 100px;
  width: 4px;
  margin: 0 0 0 0;
}

.footerMenuLink {
  color: #fff;
}

.zotZonAvatar {
  border-radius: 100px;
  width: 34px;
  margin: 0 0 0 0;
}

.colFooterText {
  margin: 20px 0 0 0;
  textalign: center;
  fontsize: 12px;
}

.colLink {
  color: rgba(0, 0, 0, 0.45);
}

.footerDiv {
  margintop: 10px;
}

.headNavi {
  margin-left: auto;
  display: flex;
}

.divPadding {
  padding: 2px 0 0 6px;
}

.divPadding1 {
  padding: 4px 0 0 12px;
}

.divPadding2 {
  padding: 3px 0 0 12px;
}

.menuOutlined {
  cursor: pointer;
  font-size: 18px;
  color: #03426b;
}

.menuWidth {
  width: 100%;
}

.divSelectWidth {
  width: 100%;
}

.searchDivH4 {
  margin-right: 20px;
}

.divSearch {
  background: #fff;
  margin: 10px 0 0 0;
  border-radius: 10px;
  display: flex;
}

.searchDiv1H4 {
  margin-right: 20px;
}

.searchButton {
  border-radius: 8px;
}

.divSearch1 {
  display: flex;
}

.searchS {
  min-width: 100px;
  border-radius: 8px;
}

.headNavi {
  margin-left: auto;
  display: flex;
}

.headNaviMenu {
  border: 1px solid #67a2b8;
  box-sizing: border-box;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 2px 4px 0 4px;
  font-size: 13px;
  margin: 0 12px 0 0;
}

.antDropdownLink {
  color: #67a2b8;
  padding: 0 2px 0 2px;
}

.aLink {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #0c6191;
}

.headPlus {
  padding: 2px 4px 0 4px;
  margin: 0 8px 0 12px;
}

.aLinkMarginColor {
  margin: 0 0px 0 4px;
  color: #888;
}

.chatDiv {
  padding-top: 64px;
  padding-bottom: 64px;
  height: 100vh;
}

.bodyChatS {
  max-width: 1116px;
  margin: 0 auto;
  border-radius: 20px;
  background: #fff;
  min-height: 90vh;
}

.chatBreadCrumb {
  padding: 10px 10px 4px 10px;
}

.roomOpenedDiv {
  padding: 100px 0 0 0;
  text-align: center;
}

.chatShowChatDiv {
  width: 100%;
  justify-content: center;
}

.chatMessagesLenghtSpace {
  width: 100%;
  justify-content: center;
}
